<template>
  <base-section
    id="Supporters"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading title="We are supported by" />

      <!-- <v-slide-group>
        <template v-for="(support,i) in items">
          <v-slide-item
            :key="i"
            class="align-self-center"
          >
            <base-img
              :src="require(`@/assets/logo/${support.logo}`)"
              color="grey"
              contain
              height="70"
              width="128"
            />
          </v-slide-item>

          <v-responsive
            v-if="i < (itemlength - 1)"
            :key="`divider-${i}`"
            class="text-center"
            height="110"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group> -->
      <v-row
        align="self-center"
        justify="center"
      >
        <template
          v-for="(support,i) in items"
        >
          <div
            :key="i"
            class="d-flex flex-row mx-1 my-1"
          >
            <v-card
              class="elevation-2"
              color="white pa-2"
            >
              <base-img
                :src="require(`@/assets/logo/${support.logo}`)"
                color="white"
                contain
                height="70"
                width="128"
              />
            </v-card>

            <v-responsive
              v-if="i < (itemlength - 1)"
              :key="`divider-${i}`"
              class="text-center hidden-sm-and-down"
              height="90"
              width="48"
            >
              <v-divider vertical />
            </v-responsive>
          </div>
        </template>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',

    data: () => ({
      supporters: [
        {
          logo: 'l-bank.jpg',
          name: 'Landeskreditbank Baden-Württemberg',
          exp: false,
        },
        {
          logo: 'BMBF.png',
          name: 'Bundesministerium für Bildung und Forschung',
          exp: false,
        },
        {
          logo: 'BMEL.png',
          name: 'Bundesministerium für Ernährung und Landwirtschaft',
          exp: false,
        },
        {
          logo: 'investBW.png',
          name: 'Invest BW: Innovationsförderung',
          exp: false,
        },
        {
          logo: 'Life-Science-Accelerator.png',
          name: 'Life Science Accelerator Mannheim',
          exp: false,
        },
        {
          logo: 'Start-Up-BW-Accelerator.png',
          name: 'Start-Up BW',
          exp: false,
        },
        {
          logo: 'BadenCampus.png',
          name: 'BadenCampus',
          exp: false,
        },
        {
          logo: 'ESF-EU-Logo.jpg',
          name: 'ESF EU',
          exp: false,
        },
        {
          logo: 'ESF-BW-Logo.jpg',
          name: 'ESF BW',
          exp: false,
        },
        {
          logo: 'invest.png',
          name: 'Invest',
          exp: '2023-12-11',
        },
      ],
    }),

    computed: {
      items () {
        const today = new Date().getTime()

        return this.supporters.filter((el) => el.exp === false || today <= new Date(el.exp).getTime())
      },

      itemlength () {
        return this.items.length
      },
    },
  }
</script>
